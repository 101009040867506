.greeting-header{
  border-bottom: 1px solid rgba(58, 51, 53, 0.12);
  height: 96px;
  display: flex;
  align-items: center;
  cursor: default;
}

.greeting-header-contacts{
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.greeting-header--contact{
  border-bottom: 3px solid #2AC084;
  margin: 0 12px;
  padding: 12px 6px;
  display: flex;
  min-width: 200px;
  max-width: 250px;
  width: 25%;
  align-items: center;
}

.contact-icon{
  font-size: 28px;
  width: 46px;
}

.contact-description{
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  letter-spacing: -0.1px;
  line-height: 21px;
  color: rgba(43, 39, 40, 0.6);
  display: flex;
  flex-direction: column;
}

.contact-icon svg,
.contact-description a,
.contact-description > .contact-a{
  color: #3A3335
}

.greeting-navigation{
  height: 60px;
  display: flex;
  width: 100%;
  align-items: center;
}
.greeting-navigation ul{
  display: flex;
  justify-content: space-around;
  width: 100%;
}
.greeting-navigation ul li{
  list-style-type: none;
}
.greeting-navigation ul li a{
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;  
  text-align: center;
  letter-spacing: -0.1px;
  color: #3A3335;
  text-decoration: none;
}

.preview-authorization h1{
  font-size: 32px;
  line-height: 35px;
}

.preview-authorization p{
  display: inline-block;
  margin-bottom: 80px;
}

.greeting-preview{}

.preview-image{
  align-items: center;
  justify-content: center;
}

.preview-image img{
  width: 47vw;
  max-width: 530px;
}
.card{
  /*padding-bottom:50px;*/
}
@media (max-width: 708px){
  .greeting-navigation ul{
    display: block;
    width: 100%;
    padding-left: 0;
    margin-left: auto;
    text-align: center;
    font-size: 23px;
  }
  .contact-description{
     /*display:none;*/
  }
  .contact-icon{
    text-align: center;
    font-size: 12px;
  }
  .greeting-header--contact{
    max-width: 55px;
    min-width: auto;
  }
  .greeting-navigation ul svg{
    margin-top:5px;
  }
  .moreButtonMobile{
    float:left;
  }
  .greeting-header-contacts{
    display: block;
    justify-content: flex-end;
    width: auto;
    margin-left: auto;
  }
  .contact-description span:nth-child(1){
    display:none;
  }
  .greeting-header--contact {
     border-bottom:none;
     margin: 0 12px;
    padding: 0;
    display: flex;
   
    max-width: 180px;
     width: auto; 
    align-items: center;
}
  .contact-icon {
    width: 25px;
  }
}
@media (max-width: 400px){
  .greeting-header--contact {
    margin: 0px;
  }
  .contact-description {
    font-size: 13px;
  }
}