.input {
  display: flex;
  flex-direction: column;
  border: 1px solid #ede8e9;
  border-radius: 8px;
  padding: 12px 18px;
}

.input input {
  width: 100%;
  border: 0px solid !important;
  padding: 0px 8px 0px 0px;
}

.input input,
.input label {
  font-family: MontserratRegular;
  font-style: normal;
  line-height: 21px;
  letter-spacing: -0.1px;
}

.input input {
  font-weight: bold;
  font-size: 14px;
  color: #3a3335;
  background: #fff;
}

.input input[placeholder]::-webkit-input-placeholder {
  font-weight: normal;
  font-size: 12px;
  color: #aaa8a8;
}

.input input[placeholder]:focus::-webkit-input-placeholder {
  color: transparent;
}

.inputWrapper > input:enabled:focus, .inputPass > div > input:enabled:focus{
  box-shadow: none !important;
  border-color: #495057 !important;
}
.p-inputtext:enabled:hover {
  border-color: #495057 !important;
}

.input label {
  font-weight: normal;
  font-size: 12px;
  color: #908e8e;
}
